import "../styles.css";
import axios from "axios";
import * as yup from "yup";
import * as formik from "formik";
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Row, Col, Card } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";

import {
  VERIFY_OTP_URL,
  RESEND_OTP_URL,
  GENERATE_OTP_URL,
  VIEW_CERTIFICATE_URL,
  GET_CERTIFICATE_FOR_AIRTEL_URL,
} from "../../../constants";
import Loader from "../../../components/Loader";
import AcrossLogo from "../../../assets/images/AcrossLogo.png";
import AirtelPaymentBank from "../../../assets/images/AirtelPaymentBank.png";

function ApbCertificate(props) {
  const { Formik } = formik;

  const [loading, setLoading] = useState(false);
  const [mobileNumber, setMobileNumber] = useState("");
  const [certificateList, setCertificateList] = useState([]);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const initialValues = { mobileNumber: mobileNumber, otp: "" };
  const schema = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("Mobile number is required to search")
      .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits"),
    otp: yup
      .string()
      .matches(/^\d{6}$/, "OTP must be exactly 6 digits")
      .required("OTP is required"),
  });

  useEffect(() => {
    // Start countdown when component mounts
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(timer);
          setIsButtonDisabled(false);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    // Cleanup timer on component unmount
    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // ****** Send Otp Mobile Api ******
  const sendOtpApi = (mobile) => {
    if (mobile?.length === 10) {
      setLoading(true);
      axios(`${GENERATE_OTP_URL}${mobile}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            setLoading(false);
          }
          if (res?.status === 400) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          if (res?.status === 404) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  // ****** Resend Otp Api ******
  const reSendOtpApi = (mobile) => {
    if (mobile?.length === 10) {
      setLoading(true);
      axios(`${RESEND_OTP_URL}${mobile}`, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (res?.status === 200) {
            toast.success(res?.data?.message);
            setLoading(false);
          }
          if (res?.status === 400) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          if (res?.status === 404) {
            toast.error(res?.data?.message);
            setLoading(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  // ****** Verify Otp Api ******
  const handleSubmit = (values) => {
    setLoading(true);
    axios(`${VERIFY_OTP_URL}${values?.mobileNumber}&otp=${values?.otp}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log("134 ==== res ====", res);
        if (res?.data?.verified === true) {
          getCertificateListApi(values?.mobileNumber);
          toast.success("Otp successfully verified.");
          setLoading(false);
        }
        if (res?.data?.verified === false) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        if (res?.status === 404) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // ****** Get Certificate List Api ******
  const getCertificateListApi = async (mobileNumber) => {
    setLoading(true);

    axios(`${GET_CERTIFICATE_FOR_AIRTEL_URL}${mobileNumber}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        console.log("162 ==== resp ====", res);
        if (res?.status === 200) {
          if (res?.data?.length > 0) {
            toast.success(res?.data?.message);
            setCertificateList(res?.data);
            setLoading(false);
          } else {
            toast.error(
              "Policy endorsement is pending due to incomplete details or incorrect video."
            );
            setLoading(false);
          }
          // setCertificateList(res?.data);
          // toast.success(res?.data?.message);
          // setLoading(false);
        }
        if (res?.status === 400) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        if (res?.status === 404) {
          toast.error(res?.data?.message);
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  // ****** Open Url Api ******
  const onViewCertificate = (documentUrl) => {
    setLoading(true);

    axios(`${VIEW_CERTIFICATE_URL}${documentUrl}`, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res?.status === 200) {
          window.open(res?.data, "_blank");
          setLoading(false);
        }
        setLoading(false);
      })
      .catch((e) => {
        if (e?.response?.status === 400) {
          toast.error("Invalid Certificate Id. Please try again.");
          setLoading(false);
        }
        setLoading(false);
      });
  };

  return (
    <React.Fragment>
      <div className="auth-wrapper text-center">
        {loading ? (
          <Loader loading={loading} />
        ) : (
          <div className="auth-content">
            <Card
              className="borderless text-center"
              // class="col-xxl-1 col-lg-1 col-md-1 col-sm-1 col-1"
              style={{ borderRadius: 8 }}
            >
              <Card.Body>
                <div
                  className="mb-4 d-row"
                  style={{ display: "flex", flexDirection: "row" }}
                >
                  <div
                    style={{
                      width: "50%",
                      textAlign: "left",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={AirtelPaymentBank}
                      style={{ width: "70%" }}
                      alt=""
                    />
                  </div>
                  <div
                    style={{
                      width: "45%",
                      // textAlign: "right",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={AcrossLogo}
                      style={{ width: "100%", marginTop: 20 }}
                      alt=""
                    />
                  </div>
                </div>

                <Formik
                  validationSchema={schema}
                  initialValues={initialValues}
                  validator={() => ({})}
                  enableReinitialize={true}
                  onSubmit={handleSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    values,
                    touched,
                    errors,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {/* ****** Mobile Number Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Mobile
                          </Form.Label>

                          <InputGroup hasValidation>
                            <Form.Control
                              type="text"
                              placeholder="Search by mobile number"
                              aria-describedby="inputGroupPrepend"
                              name="mobileNumber"
                              //   value={values.mobileNumber}
                              value={mobileNumber}
                              onChange={(e) => {
                                handleChange(e);
                                sendOtpApi(e?.target.value);
                                setMobileNumber(e.target.value);
                              }}
                              isInvalid={!!errors.mobileNumber}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.mobileNumber}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Otp Container ****** */}
                      <Row className="mb-3">
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="validationFormikUsername"
                        >
                          <Form.Label
                            style={{
                              width: "100%",
                              textAlign: "start",
                              flexDirection: "row",
                            }}
                          >
                            Mobile
                          </Form.Label>

                          <InputGroup hasValidation>
                            <Form.Control
                              placeholder="Enter Otp"
                              aria-describedby="inputGroupPrepend"
                              name="otp"
                              value={values.otp}
                              onChange={handleChange}
                              maxLength={6}
                              //   onChange={(e) => {
                              //     handleChange(e);
                              //     sendOtpApi(e?.target.value);
                              //   }}
                              isInvalid={!!errors.otp}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{
                                width: "100%",
                                textAlign: "start",
                                flexDirection: "row",
                              }}
                            >
                              {errors.otp}
                            </Form.Control.Feedback>
                          </InputGroup>
                        </Form.Group>
                      </Row>

                      {/* ****** Resend Otp Container ****** */}
                      <Row className="mb-1">
                        {isButtonDisabled ? (
                          <p style={{ textAlign: "right" }}>
                            You can resend OTP in 0{formatTime(timeLeft)}
                          </p>
                        ) : (
                          <span
                            style={{
                              color: "#1e90ff",
                              cursor: "pointer",
                              textAlign: "right",
                              textDecoration: "underline",
                            }}
                            onClick={(e) => {
                              setIsButtonDisabled(true);
                              setTimeLeft(300); // Reset the timer to 5 minutes

                              // Make API call to resend OTP
                              reSendOtpApi(values?.mobileNumber);
                            }}
                          >
                            Resend OTP
                          </span>
                        )}
                      </Row>

                      {/* ****** Button Container ****** */}
                      <Button className="w-100 mt-5 mb-5" type="submit">
                        Submit
                      </Button>

                      {/* ****** Certificate List Container ****** */}
                      {certificateList?.length > 0 && (
                        <Row className="mb-3 p-3">
                          <table className="table table-bordered">
                            <thead className="thead-dark">
                              <tr>
                                <th>Sr</th>
                                <th>Certificate No</th>
                                <th>Customer Name</th>
                                <th>Action</th>
                              </tr>
                            </thead>

                            <tbody>
                              {certificateList?.map((item, index) => {
                                return (
                                  <tr key={item?.id}>
                                    <td>{index + 1}</td>
                                    <td>{item?.certificatesNumber}</td>
                                    <td>{item?.customerName}</td>
                                    <td>
                                      <button
                                        title="View Details"
                                        className="btn btn-link"
                                        onClick={() =>
                                          onViewCertificate(item?.documentUrl)
                                        }
                                      >
                                        <i className="fas fa-eye"></i>{" "}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </Row>
                      )}
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default ApbCertificate;
